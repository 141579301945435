body {
  background-color: $background-color;
  margin: 0;
  color: $foreground-color;

  &.modal-open {
    overflow-y: hidden; }

  &.dark {
    background-color: $theme-color-06; } }

// ---------

*:focus-visible {
  outline: $border dashed $theme-color-02!important; }

// ---------

section {
  margin: $margin-unit * 3 0;

  .section-heading {
      margin-bottom: $margin-unit * 2;

      @include respond-desktop {
          margin-bottom: $margin-unit; }

      @include respond-mobile {
          margin-bottom: $margin-unit * 0.5; }

      h1 {
        margin: 0;
        @include respond-mobile {
            @include responsive-font-size($h2-font-size + 1rem);
            @include outline-shadowed-text(0.4rem, -0.8rem, 0.8rem, $theme-color-04); } }

      &.with-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          h1 {
              flex-shrink: 0;
              margin-right: $margin-unit; }

          @include respond-desktop {
            .text-btn {
                margin: $margin-unit 0; } }

          @include respond-mobile {
            .text-btn {
                margin: $margin-unit * 0.5 0; } } } } }

// ---------

.container {
  @include container;

  & > *:first-child {
    margin-top: 0; }

  & > *:last-child {
    margin-bottom: 0; } }

// ---------

img {
  width: 100%; }

// ---------

.clearfix {
  clear: both;

  &:after {
    content: '';
    display: table;
    clear: both; } }

// ---------

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  display: none;
  margin: 0; }

// ---------

.masonry {
  display: flex;

  .masonry-column {
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1; } }

// ----------

