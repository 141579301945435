
.project-container, .project-footer {
    margin-right: $sidebar-width;

    @include respond-desktop {
        margin-right: 0; } }

//--------------------------------------------------------

.project-grid-container {
    @include respond-desktop {
        margin-top: $margin-unit * 3; }

    @include respond-mobile {
        margin-top: $margin-unit; } }

//--------------------------------------------------------

.project-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$margin-unit;
    margin-right: -$margin-unit;
    margin-top: $margin-unit * 1.5;

    @include respond-desktop {
        margin-top: $margin-unit * 0.75; }

    @include respond-tablet {
        margin-top: $margin-unit * 0.75; }

    @include respond-mobile {
        margin-top: $margin-unit * 0.25;
        margin-left: -$margin-unit * 0.5;
        margin-right: -$margin-unit * 0.5; }

    .project-item {
        flex-basis: 27%;
        flex-shrink: 1;
        padding: $margin-unit * 0.5 $margin-unit;
        position: relative;

        @include respond-desktop {
            flex-basis: 26%; }

        @include respond-max(940px) {
            flex-basis: 41%; }

        @include respond-tablet {
            flex-basis: 39%; }

        @include respond-max(640px) {
            flex-basis: 38%; }

        @include respond-mobile {
            flex-basis: 37.5%;
            padding: $margin-unit * 0.25 $margin-unit * 0.75; }

        @include respond-max(440px) {
            flex-basis: 80%; } } }


//--------------------------------------------------------

.project-sidebar {
    .sidebar-content, .bubble {
        background-image: url('../../../_img/pink-background.png'); }

    .bubble {
        top: 13rem;
        border-color: $theme-color-04;

        i {
            color: $theme-color-04; }

        &::after {
            background-image: url('../../../_img/pink-arrow-right.png'); }

        @include respond-mobile {
            top: 11rem; }

        .action-close {
            margin-right: -$margin-unit * 0.5; } }

    .sidebar-content {
        overflow-y: scroll!important;

        &::-webkit-scrollbar {
            display: none; }

        @include respond-desktop {
            &.closed {
                overflow: hidden!important; } }

        .heading {
            margin-top: 14rem;
            margin-bottom: $margin-unit;
            margin-left: $margin-unit;

            @include respond-mobile {
                margin-top: 12rem; }



            h3 {
                margin-bottom: 0;
                font-size: $h3-font-size!important; } }

        a {
            position: relative;
            display: block;
            color: $theme-color-02;
            margin: $margin-unit * 0.9;
            padding: $margin-unit * 0.1;
            -webkit-tap-highlight-color: transparent;

            h6 {
                position: relative;
                z-index: 2;
                margin: 0;
                display: inline-block;
                line-height: 1.2;
                color: $theme-color-04;
                font-weight: 400; }

            &::before {
                content: '';
                background: transparent;
                @include prefix(transition, $transition-speed);
                @include highlight; }

            &:hover {
                @include grow(1.1);

                h6 {
                    color: $theme-color-01; }

                &::before {
                    background: $theme-color-04; } }

            &:active {
                @include grow(0.9);

                &::before {
                    background: $theme-color-04; } } } } }

//--------------------------------------------------------

.project-container {

    @include respond-desktop {
        margin-top: $margin-unit * 3; }

    @include respond-mobile {
        margin-top: $margin-unit; }

    .section-heading {
        margin-bottom: 0;

        @include respond-desktop {
            margin-right: $sidebar-bubble-width; }

        h4 {
            text-transform: none;
            margin-bottom: 0; }

        .notice {
            margin-top: $margin-unit * 0.5; } }

    section {
        margin: 0;
        padding-top: $margin-unit * 3;
        margin-bottom: $margin-unit;

        @include respond-tablet {
            padding-top: $margin-unit * 2;
            margin-bottom: $margin-unit * 0.5; }

        @include respond-mobile {
            padding-top: $margin-unit; }

        h2 {
            margin-top: 0; } }

    p {
        max-width: $max-container-width * 0.8;
        margin-left: auto;
        margin-right: auto; }

    .desktop-title {
        @include respond-tablet {
            display: none; } }

    .tablet-title {
        display: none;

        @include respond-tablet {
            display: block; } }

    img {
        border-radius: $border-radius; }

    img.rotated {
        @include respond-tablet {
            transform: none!important; } }

    img.bordered {
        border: $border * 0.3 solid $theme-color-03; } }


//--------------------------------------------------------

.project-section-sidebyside {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-height: 60rem;
        object-fit: contain; }

    .right, .left {
        flex-basis: 45%;
        flex-shrink: 1;
        flex-grow: 1; }

    .right {
        margin-left: $margin-unit * 2; }

    .section-footer {
        flex-basis: 100%; }

    @include respond-tablet {
        flex-wrap: wrap;
        display: block;

        .right {
            margin-left: 0;
            margin-top: $margin-unit * 0.5; } } }

//--------------------------------------------------------

.project-section-stacked {
    text-align: center;

    @include respond-tablet {
        text-align: left; }

    .text {
        max-width: 80%;
        margin: 0 auto; } }

//--------------------------------------------------------

.project-images-side-by-side {
    display: flex;
    margin: $margin-unit * 0.75 0;

    .image-container {
        flex-basis: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        margin: 0 $margin-unit * 0.5; }

    @include respond-mobile {
        display: block;
        margin: 0;

        .image-container {
            margin: $margin-unit * 0.25 0;

            &:last-child {
                margin-bottom: 0; } } } }

//--------------------------------------------------------

.project-images-stacked {}

//--------------------------------------------------------

.project-section-links {
    text-align: center; }

//--------------------------------------------------------

.project-link {
    margin: $margin-unit auto;

    a {
        display: inline-block;
        background-color: $theme-color-02;
        color: $theme-color-04;
        padding: $padding-unit * 0.25 $padding-unit * 0.5;
        border-radius: $border-radius;
        position: relative; }

    span {
        font-size: $min-font-size;
        text-transform: none;
        line-height: 1.2;
        position: absolute;
        display: inline-block;
        font-style: italic;
        color: $theme-color-03;
        width: 15rem;
        text-align: left;
        right: -15rem - ($margin-unit * 0.5); } }

//--------------------------------------------------------

.project-gallery {
    h2, p {
        text-align: center;

        @include respond-tablet {
            text-align: left; } }

    .masonry-column {
        margin: 0 $gallery-item-gap;

        @include respond-mobile {
            margin: 0 $gallery-item-gap * 0.5; }

        &:first-child {
            margin-left: 0; }
        &:last-child {
            margin-right: 0; } } }
