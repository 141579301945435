$header-splash-collapsed-height: 70rem;
$header-splash-collapsed-height-desktop: 72rem;
$header-splash-collasped-height-mobile: 56rem;
$header-namebox-rotation: -4deg;
$header-namebox-max-width: 49rem;
$header-namebox-max-width-desktop: 38rem;
$header-namebox-max-width-tablet: 33rem;
$header-namebox-max-width-mobile: 24rem;

@mixin header-splash-expanded {
    position: absolute;
    inset: 0;
    bottom: unset;
    height: 100vh;
    visibility: unset;
    z-index: 50;
    padding: 0;
    @include prefix(transition, 0.5s);

    .header-namebox {
        opacity: 0;
        transform: translateX(-$header-namebox-max-width - 10rem); }

    .left {
        @include respond-desktop; }


    .right {
        transform: translateX(-25rem);

        @include respond-desktop {
            transform: translateX(0rem) translateY(15%); } } }



@mixin header-splash-collapsed {
    position: absolute;
    background-color: unset!important;
    inset: 0;
    bottom: unset;
    height: $header-splash-collapsed-height;
    visibility: unset;
    z-index: 1;
    @include prefix(transition, 0.5s);

    @include respond-desktop {
        padding-bottom: 0;
        height: $header-splash-collapsed-height-desktop; }

    @include respond-mobile {
        padding-top: $padding-unit * 2;
        height: $header-splash-collasped-height-mobile; } }

@mixin header-splash-gray {
    background-color: lighten($theme-color-02, 40%);
    @include prefix(transition, 0.25s); }

@mixin header-splash-bright {
    background-color: $theme-color-01; }

.header-splash {
    overflow: hidden;
    &.expanded {
        @include header-splash-expanded; }
    &.collapsed {
        @include header-splash-collapsed; }
    &.gray {
        @include header-splash-gray; }
    &.bright {
        @include header-splash-bright; }

    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $padding-unit * 3;
    padding-bottom: $padding-unit;

    @include respond-desktop {
        flex-direction: column-reverse; }

    .left, .right {
        display: flex;
        align-items: center;
        position: relative; }

    .left {
        z-index: 2;

        @include respond-desktop {
            margin: 0;
            margin-top: -17rem; }

        @include respond-mobile {
            margin-top: -13rem;
            margin-left: 2rem; } }

    .right {
        z-index: 1;
        margin-left: -25rem;
        margin-right: -10rem;
        flex-basis: 85rem;
        @include prefix(transition, 0.5s);

        @include respond-desktop {
            flex-basis: 45rem;
            max-width: 60rem;
            margin: 0; }

        @include respond-mobile {
            max-width: 50rem;
            flex-basis: 36rem; }

        .loading {
            position: absolute;
            bottom: -2rem;
            left: 50%;
            margin-left: -5rem;
            font-size: $h5-font-size;
            font-weight: 400;
            text-transform: uppercase;
            @include prefix(transition, $transition-speed);

            &.inactive {
                opacity: 0; } } }

    img.inactive {
        display: none; } }

//--------------------------------------------------------------------

.header-splash-placeholder {
    position: relative;
    display: block;
    height: $header-splash-collapsed-height;
    padding-top: $padding-unit * 3;
    padding-bottom: $padding-unit;
    width: 100%;

    @include respond-desktop {
        padding-bottom: 0;
        height: $header-splash-collapsed-height-desktop; }

    @include respond-mobile {
        padding-top: $padding-unit * 2;
        height: $header-splash-collasped-height-mobile; } }

//--------------------------------------------------------------------

.header-namebox {
    width: $header-namebox-max-width + 10rem;
    text-transform: uppercase;
    margin: $margin-unit;
    margin-left: 0;
    @include prefix(transition, 0.5s);

    @include respond-desktop {
        width: $header-namebox-max-width-tablet + 10rem; }
    @include respond-mobile {
        width: $header-namebox-max-width-mobile + 10rem;
        margin-right: $margin-unit * 0.5; }

    .heading {
        position: relative;
        z-index: 2;
        display: inline-block;
        color: $theme-color-01;
        padding: $padding-unit * 0.5 $padding-unit;
        border-radius: $border-radius;
        transform: rotate($header-namebox-rotation);
        background-color: $theme-color-04;

        @include respond-mobile {
            padding: $padding-unit * 0.25 $padding-unit * 0.5; }

        h4 {
            margin: 0;
            @include respond-desktop {
                font-size: 2rem; } } }

    .bubble {
        max-width: $header-namebox-max-width;
        position: relative;
        z-index: 1;
        margin-left: $margin-unit;
        margin-top: $margin-unit * -1;
        padding: $padding-unit * 1.5;
        padding-right: $padding-unit;
        transform: rotate($header-namebox-rotation);
        border-radius: $border-radius;
        background-image: url('../../../_img/pink-background.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        @include respond-desktop {
            max-width: $header-namebox-max-width-tablet; }
        @include respond-mobile {
            width: $header-namebox-max-width-mobile;
            margin-top: $margin-unit * -0.5;
            margin-left: $margin-unit * 0.5;
            padding: $padding-unit;
            padding-right: $padding-unit * 0.75; }

        h1 {
            margin: 0;
            @include respond-desktop {
                font-size: $h2-font-size;
                @include outline-shadowed-text(0.4rem, -0.8rem, 0.8rem, #000); }
            @include respond-mobile {
                font-size: $h3-font-size * 1.2;
                @include outline-shadowed-text(0.3rem, -0.6rem, 0.6rem, #000); } }

        &::after {
            content: '';
            position: absolute;
            right: -0.8rem;
            bottom: -2rem;
            transform: rotate($header-namebox-rotation);
            width: 5rem;
            height: 5rem;
            background-size: contain;
            background-image: url('../../../_img/pink-arrow-right.png');

            @include respond-desktop {
                bottom: unset;
                transform: rotate(280deg);
                top: 5rem;
                right: -2.8rem; } } }

    .namebox-footer {
        display: flex;
        justify-content: flex-end;
        transform: rotate($header-namebox-rotation);
        margin-top: $margin-unit * 0.5;

        @include respond-mobile {
            justify-content: center;
            margin-top: 0; } } }
