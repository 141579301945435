.section-about {
    text-align: right;
    margin-bottom: 0;
    padding: $padding-unit * 2 0;

    @include respond-desktop {
        padding: 0;
        margin-top: -$margin-unit;
        padding-bottom: $padding-unit; }

    @include respond-tablet {
        margin-top: $margin-unit * 4; }

    @include respond-mobile {
        margin-top: $margin-unit;
        padding: 0;
        text-align: center; }

    .text-btn {
        margin-right: 0;
        float: right;

        @include respond-mobile {
            float: none; } }

    h2.tablet {
        display: none;
        @include respond-tablet {
            display: block;
            text-align: center; } }

    h2.desktop {
        @include respond-tablet {
            display: none; } }

    p {
        @include respond-tablet {
            text-align: left; } }

    .container {
        display: flow-root; }

    .hero {
        float: left;
        width: 60rem;
        height: auto;

        margin-left: $margin-unit * -1;
        margin-right: $margin-unit * 2;
        shape-margin: $margin-unit * 2;
        shape-outside: url('../../../_img/about-me-photo.png');

        @include respond-desktop {
            width: 50rem;
            margin-top: $margin-unit * 3; }

        @include respond-max(880px) {
            width: 60%;
            shape-margin: $margin-unit;
            margin-top: $margin-unit; }

        @include respond-mobile {
            float: none;
            width: 80%;
            margin: 0 auto; } } }
