

//---------------------------------------------------

$circle-btn-size: 8rem;

.circle-btn {
	@include prefix(border-radius, 50%);

	cursor: pointer;
	margin: $margin-unit * 0.5;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $circle-btn-size;
	height: $circle-btn-size;
	text-decoration: none;
	background-color: $theme-color-04;
	color: $theme-color-01;
	border: $border solid $theme-color-02;
	box-shadow: -0.3rem 0.3rem 0 $theme-color-02;

	@include respond-tablet {
		width: $circle-btn-size * 0.75;
		height: $circle-btn-size * 0.75;
		margin: $margin-unit * 0.25; }

	i {
		font-size: $circle-btn-size / 2;
		color: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: -0.4rem;

		@include respond-tablet {
			font-size: ($circle-btn-size * 0.75) / 2;
			margin-top: -0.2rem; } }

	&.muted {
		background-color: transparent;
		border-color: $theme-color-03;
		box-shadow: -0.3rem 0.3rem 0 $theme-color-03;

		i {
			color: $theme-color-03; }

		&:hover i {
			color: $theme-color-02; }

		&:active i {
			color: $theme-color-04; } }

	&:hover {
		color: $theme-color-02;
		border-color: $theme-color-02;
		box-shadow: -0.3rem 0.3rem 0 $theme-color-02;
		@include grow(1.1); }

	&:active {
		background-color: $theme-color-05;
		color: $theme-color-04;
		border-color: $theme-color-04;
		box-shadow: -0.3rem 0.3rem 0 $theme-color-04;
		@include grow(0.9); }

	&:focus-visible {
		outline-offset: -1rem; } }



//---------------------------------------------------

.text-btn {
	@include prefix(border-radius, $border-radius);

	cursor: pointer;
	padding: $padding-unit * 0.5 $padding-unit;
	margin-bottom: 0.3rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	background-color: $theme-color-04;
	color: $theme-color-01;
	border: $border solid $theme-color-02;
	box-shadow: -0.3rem 0.3rem 0 $theme-color-02;

	@include respond-mobile {
		padding: $padding-unit * 0.5; }

	i:first-child {
		margin-right: $margin-unit * 0.5;
		@include respond-mobile {
			margin-right: $margin-unit * 0.25; } }

	i:last-child {
		margin-left: $margin-unit * 0.5;
		@include respond-mobile {
			margin-left: $margin-unit * 0.25; } }

	&:hover {
		color: $theme-color-02;
		border-color: $theme-color-02;
		box-shadow: -0.3rem 0.3rem 0 $theme-color-02;
		@include grow(1.1); }

	&:active {
		background-color: $theme-color-05;
		color: $theme-color-04;
		border-color: $theme-color-04;
		box-shadow: -0.3rem 0.3rem 0 $theme-color-04;
		@include grow(0.9); }

	&:focus-visible {
		outline-offset: -1rem; } }

//---------------------------------------------------

.sticker-btn {
	cursor: pointer;
	position: relative;
	display: inline-block;

	img {
		user-select: none; }

	.main-img {
		position: relative;
		z-index: 2;
		@include prefix(transition, $transition-speed); }

	.backing-img {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0; }

	&:hover {
		.main-img {
			transform: translate(-1rem, -1rem);
			z-index: 10; } }

	&:active {
		.main-img {
			transform: translate(-0.5rem, -0.5rem);
			filter: grayscale(100%); } } }

//---------------------------------------------------

.gallery-btn, .portfolio-btn {
    padding: 0;
    outline: 0;
    border: 0;
    background: $theme-color-05;
    width: 100%;
    cursor: pointer;
    margin: 0;
    border-radius: $border-radius;
    border: $border * 3.5 solid $theme-color-05;
    outline: $border * 0.3 solid $theme-color-03;
    position: relative;

    &:hover {
        @include prefix(transform, translate(-1.5rem, -1.5rem)!important);
        @include prefix(box-shadow, 1.5rem 1.5rem 0 $theme-color-03); }

    img {
        width: 100%;
        background-color: lighten($theme-color-01, 20%);
        margin-bottom: -0.4rem;
        border-radius: $border-radius - 0.4rem; } }

//---------------------------------------------------

.portfolio-btn {
	display: block;
	position: relative;

	h5 {
		font-weight: 400;
		text-align: center;
		color: $theme-color-03;
		line-height: 1.3;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: $margin-unit * 0.5;

		span, i {
			display: inline-block; }

		i {
			margin-left: 1rem;
			font-size: $min-font-size; } } }
