.nav-text {
    margin: $margin-unit * 0.75 0;

    .link {
        display: inline-block;
        margin: $padding-unit; }

    &.muted {
        .link a {
            color: $theme-color-03;

            &:hover {
                color: $theme-color-02; }

            &:active {
                color: $theme-color-04; } } } }

//--------------------------------------------

.nav {
    .sidebar {
        z-index: 10;

        &.closed {
            .link a {
                opacity: 0;
                user-select: none; } } }

    .sidebar-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include respond-mobile {
            h5 {
                font-size: $h5-font-size; } }

        .link {
            margin: $margin-unit 0;

            a {
                position: relative;
                display: block;
                color: $theme-color-02;
                -webkit-tap-highlight-color: transparent;

                h5 {
                    position: relative;
                    z-index: 2;
                    font-weight: 400; }

                &::before {
                    content: '';
                    background: $theme-color-05;
                    @include prefix(transition, $transition-speed);
                    @include highlight; }

                &:hover {
                    color: $theme-color-04;
                    @include grow(1.1);

                    &::before {
                        background: $theme-color-02; } }

                &:active {
                    color: $theme-color-05;
                    @include grow(0.9);

                    &::before {
                        background: $theme-color-04; } } }

            &.selected a {
                color: $theme-color-04;

                &:hover {
                    &::before {
                        background: $theme-color-01; } }

                &::before {
                    background: $theme-color-01; } } } } }
