.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    &.closed {
        .sidebar-content {
            right: -$sidebar-width;
            overflow: hidden;

            @include respond-mobile {
                right: -100vw; } }

        .bubble {
            i.action-close {
                display: none; } } }

    &.open {
        top: 0;
        bottom: 0;
        right: 0;

        .bubble {
            background-image: none;
            border-bottom: 0;

            &::after, i.action-open {
                display: none; } } }

    &.noCollapseOnLargeScreens {
        &.closed .sidebar-content {
            @include respond-min($desktop+1) {
                right: 0; } }

        .bubble {
            @include respond-min($desktop+1) {
                visibility: collapse; }
            @include respond-desktop {
                visibility: visible; } } }

    .bubble {
        position: absolute;
        top: $margin-unit;
        right: $margin-unit;
        padding: $padding-unit * 0.5 $padding-unit * 0.75;
        cursor: pointer;
        background-color: unset;
        background-image: url('../../_img/white-background.png');
        background-size: 111rem;
        background-position: -15rem 14rem;
        border-radius: 45%;
        outline: none;
        border: 0;
        border-bottom: $border solid $theme-color-02;
        -webkit-tap-highlight-color: transparent;
        z-index: 2;

        @include respond-mobile {
            top: $margin-unit * 0.3;
            right: $margin-unit * 0.3; }

        &::after {
            position: absolute;
            content: '';
            display: block;
            top: 4.7rem;
            right: 0.5rem;
            width: 3rem;
            height: 3rem;
            background-image: url('../../_img/white-arrow-right.png');
            background-size: contain;
            background-repeat: no-repeat;

            @include respond-mobile {
                top: 4.1rem;
                width: 2.4rem;
                height: 2.4rem; } }

        &:hover {
            @include grow(1.1); }

        &:active {
            @include grow(0.9); }

        i {
            color: $theme-color-02;
            font-size: $h4-font-size;

            @include respond-mobile {
                font-size: $h5-font-size; } } }

    .sidebar-content {
        position: absolute;
        overflow: hidden;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        width: $sidebar-width;
        background-image: url('../../_img/white-background.png');
        background-size: 55rem;
        background-position: center;
        @include prefix(transition, $transition-speed);

        @include respond-mobile {
            width: 100vw; } } }
