.section-error-404 {
    text-align: center;
    margin-bottom: 0;

    .container {
        max-width: 85rem; }

    .page-not-found {
        margin-left: 6rem;
        user-select: none;
        pointer-events: none;
        max-width: 85rem; }

    .body {
        text-align: left;
        margin-top: -22rem;
        margin-bottom: 10rem;
        margin-left: 8rem; }

    @include respond-max(940px) {
        .container {
            overflow: hidden; } }

    @include respond-tablet {
        .page-not-found {
            margin-left: 4rem; }
        .body {
            margin-left: 6rem;
            max-width: 32rem;
            margin-top: -17rem; } }

    @include respond-mobile {
        .page-not-found {
            margin-left: 3rem; }
        .body {
            max-width: 25rem;
            margin-left: 4rem;
            margin-top: -14rem; } }

    @include respond-max(440px) {
        .body {
            max-width: 20rem;
            margin-top: -12rem; } }

    @include respond-max(380px) {
        .page-not-found {
            margin-left: 2.5rem; }
        .body {
            margin-left: 3.5rem;
            margin-top: -10rem;

            p {
                max-width: 15rem; } } } }
