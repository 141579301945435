$sticker-max-width: 30rem;

.section-skills {
    h2 {
        text-align: center; }

    .stickers {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap; }

    .sticker-btn {
        margin: 0 $margin-unit * 0.5;
        @include respond-mobile {
            flex-basis: 30%;
            flex-grow: 1; } }

    .sticker-01 { // art direction
        max-width: $sticker-max-width; }
    .sticker-02, .sticker-03 { // illustration, writing
        max-width: $sticker-max-width - 2rem; }

    .sticker-04 { // coding
        max-width: $sticker-max-width - 6rem; }

    .sticker-05 { // design
        max-width: $sticker-max-width - 4rem; }

    .sticker-06 { // animation
        max-width: $sticker-max-width + 2rem; }

    .section-footer {
        display: flex;
        align-items: center;
        margin-top: $margin-unit;
        justify-content: flex-end;

        @include respond-tablet {
            margin-top: 0;
            flex-wrap: wrap;
            justify-content: center; }

        .text-btn {
            flex-shrink: 0;
            margin-right: $margin-unit;

            &:last-child {
                margin-right: 0; }

            @include respond-mobile {
                margin-top: 0; }

            @include respond-max(340px) {
                margin: $margin-unit * 0.25; } }

        p {
            text-align: right;
            margin-right: $margin-unit;
            max-width: 52rem;

            @include respond-tablet {
                margin-right: 0;
                max-width: 50rem;
                width: 100%;
                text-align: center; } } } }
