.section-contact {
    margin-top: 0;
    padding-top: $margin-unit * 3;
    @include prefix(transition, $transition-speed);

    .sent-message {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        position: absolute;
        inset: 0;
        top: 8rem;
        pointer-events: none;

        .graphic {
            background-size: contain;
            background-repeat: no-repeat;

            width: 36rem;
            height: 32rem;
            @include respond-mobile {
                width: 26rem;
                height: 22rem; } }

        p {
            margin: 0; } }

    &.sending, &.success, &.error {
        form {
            opacity: 0; }

        .sent-message {
            opacity: 1; } }

    &.sending {
        @include keyframes(send-bob) {
            0% {
                background-position: center; }
            25% {
                background-position: top; }
            50% {
                background-position: center; }
            75% {
                background-position: bottom; }
            100% {
                background-position: center; } }

        .sent-message {
            .graphic {
                background-image: url('../../../_img/contact-success.png');
                animation: send-bob 1s infinite;
                animation-timing-function: linear; }

            .success-msg, .error-msg {
                display: none; } } }

    &.success {
        .graphic {
                background-image: url('../../../_img/contact-success.png'); }
        .sent-message {
            .sending-msg, .error-msg {
                display: none; } } }

    &.error {
        .sent-message {
            .graphic {
                background-image: url('../../../_img/contact-failure.png'); }
            .sending-msg, .success-msg {
                display: none; } } }


    .container {
        display: flow-root;
        max-width: 90rem;
        position: relative;
        @include respond-mobile {
            text-align: center; } }

    form {
        margin-top: $margin-unit * 2; }

    input, textarea {
        outline: none;
        resize: none;
        width: 100%;
        border: 0;
        padding: 0;
        font-family: $body-font;
        font-weight: 300;
        color: $theme-color-04;
        background: transparent;
        font-size: $body-font-size;

        &:focus-visible {
            outline: none!important; } }


    .input-name, .input-email, .input-message {
        margin: $margin-unit * 1.5 0;
        padding: $padding-unit * 0.75;
        background-image: url('../../../_img/white-background.png');
        background-size: 60rem;
        border-radius: $border-radius;
        border-bottom: $border solid $theme-color-02;
        position: relative;

        input {
            position: relative;
            z-index: 2; }

        &::after {
            content: '';
            position: absolute;
            display: block;
            background-image:  url('../../../_img/white-arrow-left.png');
            background-size: contain;
            width: 5rem;
            height: 5rem;
            background-repeat: no-repeat;
            bottom: -1.9rem;
            left: -1rem;
            transform: rotate(9deg);
            z-index: 1; } }

    .input-email, .input-name {
        margin: $margin-unit 0;
        max-width: 60rem; }

    .input-name {
        margin-right: 10rem;

        @include respond-mobile {
            margin-right: 0rem; } }

    .input-email {
        background-position: bottom;
        margin-left: 10rem;
        margin-bottom: $margin-unit * 1.5;

        @include respond-mobile {
            margin-left: 0rem;
            margin-bottom: $margin-unit; } }

    .input-message {
        background-position: bottom right;
        margin-bottom: $margin-unit;

        @include respond-mobile {
            margin-top: $margin-unit; } }

    .text-btn {
        float: right;

        @include respond-mobile {
            float: none; } }

    .error {
        display: block;
        position: absolute;
        font-size: $min-font-size;
        background-color: $theme-color-02;
        color: $theme-color-04;
        padding: 0.5rem 1rem;
        border-radius: $border-radius;
        top: -1rem;
        right: 1rem; } }

