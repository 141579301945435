.footer {
    text-align: center;
    padding: $margin-unit * 2 0;

    .navbar-text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .link {
            margin: $margin-unit * 0.5;
            flex-shrink: 0; } }

    p {
        color: $theme-color-03; }

    a {
        display: inline-flex; } }
