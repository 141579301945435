.lightbox {
    position: fixed;
    inset: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;

    .dark-overlay {
        position: absolute;
        inset: 0;
        border: 0;
        outline: 0;
        background-color: rgba($theme-color-03, 0.6);
        z-index: 1; }

    .circle-btn {
        position: relative;
        z-index: 2;
        flex-shrink: 0;
        -webkit-tap-highlight-color: transparent;

        @include respond-mobile {
            position: absolute;
            z-index: 3;

            &.prev {
                bottom: $margin-unit;
                right: ($margin-unit * 0.5) + 8rem; }
            &.next {
                bottom: $margin-unit;
                right: $margin-unit * 0.6; } } }

    .content {
        position: relative;
        z-index: 2;
        background-color: $theme-color-04;
        border-radius: $border-radius;
        width: 80vw;
        height: 90vh;
        display: flex;
        flex-shrink: 1;
        border: $padding-unit solid $theme-color-04;

        @include respond-desktop {
            flex-direction: column;
            max-height: 90vh;
            height: unset; }
        @include respond-mobile {
            border-width: $padding-unit * 0.5;
            position: absolute;
            inset: 0;
            width: unset;
            height: unset;
            max-width: unset;
            max-height: unset;
            border-radius: 0; } }

    .image, .video, .pdf {
        max-width: 60%;
        max-height: 100%;
        object-fit: contain;

        @include respond-desktop {
            max-width: 100%;
            max-height: 72vh; }

        @include respond-mobile {
            max-height: 60vh; } }

    .pdf {
        background-color: $theme-color-05;
        width: 60%;
        height: 100%;

        @include respond-desktop {
            width: 100%;
            height: 72vh; }

        @include respond-tablet {
            display: none; } }

    .pdf-link {
        display: none;

        @include respond-tablet {
            display: inline-flex; } }

    .text-container {
        width: 40%;
        flex-shrink: 1;
        overflow-y: scroll;
        max-height: 100%;
        align-self: center;
        text-align: center;
        margin-left: $margin-unit;
        margin-right: -$padding-unit + 1rem;
        padding: 0 $padding-unit;
        padding-left: 0;

        &::-webkit-scrollbar {
            display: none; }

        @include respond-desktop {
            margin-left: 0;
            width: 100%; }

        @include respond-mobile {
            text-align: left;
            margin-top: 0;
            padding-bottom: 7rem; }

        .text-btn {
            margin-bottom: $margin-unit; }

        p, h3 {
            text-align: left; }

        h3 {
            margin-top: $margin-unit;
            @include respond-desktop {
                margin-top: $margin-unit * 1.25;
                margin-bottom: $margin-unit * 0.75; }
            @include respond-mobile {
                margin: $margin-unit * 0.5 0; } }

        p {
            color: $theme-color-05;

            a {
                color: $theme-color-01; }
            a:hover {
                color: $theme-color-02; }
            a:active {
                color: $theme-color-06; }
            @include respond-mobile {
                line-height: 1.2; } }

        .meta {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: $min-font-size;
            margin: $margin-unit * 1.5 0;
            text-align: left;
            flex-wrap: wrap;

            &:last-child {
                margin-bottom: 0; }

            @include respond-mobile {
                display: block;
                margin-top: 0;
                margin-bottom: $margin-unit * 0.75; }

            i {
                margin-right: 0.5rem; }

            .date {
                display: flex;
                align-items: center;
                flex-shrink: 0;

                @include respond-mobile {
                    display: inline-block;
                    text-align: left; } }

            label, .date {
                position: relative;
                z-index: 2;
                margin-left: 0.8rem;
                margin-bottom: 1rem;
                &::before {
                    margin-top: -0.3rem;
                    content: '';
                    display: block;
                    @include highlight;
                    background-color: $theme-color-02;
                    z-index: -1; } }


            .skills, .tools {
                text-align: left;
                flex-shrink: 1;
                max-width: 16rem;
                margin-right: $margin-unit * 1.5;
                margin-bottom: $margin-unit * 0.75;

                label {
                    display: inline-block; }

                label, span {
                    color: $theme-color-04; }
                span {
                    display: block;
                    position: relative;
                    z-index: 2;
                    font-style: italic;
                    color: rgba($theme-color-05, 0.4); }

                @include respond-mobile {
                    max-width: unset; } } } }

    .close {
        position: absolute;
        top: -$padding-unit * 0.5;
        right: -$padding-unit * 0.5;
        font-size: $h4-font-size;
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        color: $theme-color-02;
        z-index: 5;

        @include respond-desktop {
            padding: 0.5rem 1rem;
            top: -$padding-unit * 0.8;
            right: -$padding-unit * 0.8;
            background-color: $theme-color-04;
            border-radius: $border-radius; }

        @include respond-mobile {
            top: -$padding-unit + 2rem;
            right: -$padding-unit + 1rem;
            padding: 0.25rem 1.25rem; } } }

