// --------------------------------------------------------------------------
// -- Responsive design handlers
// --------------------------------------------------------------------------

@mixin respond-min($value) {
  @media (min-width: #{$value}) {
    @content; } }

@mixin respond-max($value) {
  @media (max-width: #{$value}) {
    @content; } }

// Responds to mobile devices, assumes max-width
@mixin respond-mobile {
  @media only screen and (max-width: $mobile) {
    @content; } }

// Responds to less than tablet devices, assumes max-width
@mixin respond-tablet {
  @media only screen and (max-width: $tablet) {
    @content; } }

// Responds to less than desktop devices, assumes max-width
@mixin respond-desktop {
  @media only screen and (max-width: $desktop) {
    @content; } }

// Responds to less than oversize devices, assumes max-width
@mixin respond-oversize {
  @media only screen and (max-width: $oversize) {
    @content; } }

// --------------------------------------------------------------------------
// -- Animation Vendor Prefixing
// --------------------------------------------------------------------------

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @-moz-keyframes #{$name} {
    @content; }
  @-ms-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }

// --------------------------------------------------------------------------
// -- Standard Vendor Prefixing
// --------------------------------------------------------------------------

@mixin prefix($command, $arguments) {
  -webkit-#{$command}: #{$arguments};
  -moz-#{$command}: #{$arguments};
  -ms-#{$command}: #{$arguments};
  -o-#{$command}: #{$arguments};
  #{$command}: #{$arguments}; }

// --------------------------------------------------------------------------
// -- Display: None workaround using visibility
// --------------------------------------------------------------------------

@mixin hidden {
  visibility: hidden;
  position: absolute; }

@mixin visible {
  visibility: visible;
  position: unset; }

// --------------------------------------------------------------------------
// -- Grow Button Animation
// --------------------------------------------------------------------------

@mixin grow($size) {
  @include prefix(transform, scale($size, $size)); }

// --------------------------------------------------------------------------
// -- Responsive Font Sizes
// --------------------------------------------------------------------------

@mixin responsive-font-size($desktop, $tablet: $desktop * 0.9, $mobile: $desktop * 0.63) {
  font-size: $desktop;

  @include respond-tablet {
    @if $tablet < $min-font-size {
      font-size: $min-font-size; }
    @else {
      font-size: $tablet; } }

  @include respond-mobile {
    @if $mobile < $min-font-size {
      font-size: $min-font-size; }
    @else {
      font-size: $mobile; } } }

// --------------------------------------------------------------------------
// -- Highlight behind text
// Ensure the parent element has position: relative, and call this in
// the &::before, specifiying your own background color
// --------------------------------------------------------------------------

@mixin highlight {
  display: block;
  height: 80%;
  width: 100%;
  margin-left: -0.5rem;
  margin-right: -0.3rem;
  position: absolute;
  transform: rotate(2deg);
  top: -0.1rem;
  left: -0.1rem;
  border-radius: 20% 25% 20% 24%;
  padding: 1rem 0.3rem 0.3rem 1rem;
  z-index: 1; }

// --------------------------------------------------------------------------
// -- Remove paddings and margins
// --------------------------------------------------------------------------

@mixin remove-padding-margin {
  padding: 0;
  margin: 0; }

// --------------------------------------------------------------------------
// -- Container Padding, Sizing
// --------------------------------------------------------------------------

@mixin container {
  max-width: $max-container-width + $padding-unit * 2;
  padding: 0 $padding-unit;
  margin: 0 auto;
  width: 100%;
  @include prefix(box-sizing, border-box);

  @include respond-desktop {
    padding: 0 $padding-unit * 2; }

  @include respond-tablet {
    padding: 0 $padding-unit * 1.5; }

  @include respond-mobile {
    padding: 0 $padding-unit * 0.5; } }

// --------------------------------------------------------------------------
// -- Clearfix
// --------------------------------------------------------------------------

@mixin clearfix-after {
  &:after {
    content: '';
    clear: both;
    display: table; } }

// --------------------------------------------------------------------------
// -- Tile Transition Animation
// --------------------------------------------------------------------------

@include keyframes(tile-transistion) {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }
