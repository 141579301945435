// Colors

$theme-color-01: #FFCC66;
$theme-color-02: #FF6699;
$theme-color-03: #CC9900;
$theme-color-04: #000000;
$theme-color-05: #ffffff;
$theme-color-06: #3D3D3D;
$theme-color-07: #be184f;

$background-color: $theme-color-01;
$foreground-color: $theme-color-04;


// Container Variables

$max-container-width: 	110rem;
$sidebar-width: 30rem;
$sidebar-bubble-width: 8rem;

// Responsive Breakpoints

$mobile:        	568px;
$tablet:        	768px;
$desktop:       	1024px;
$oversize:			1280px;

// Paddings and Margins

$padding-unit: 		3rem;
$margin-unit: 		3rem;

// Typography
@font-face {
    font-family: 'Comicy';
    src: url('./fonts/comicy.ttf') format('truetype'); }

@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200;300;500;700&display=swap');

$h1-font-size: 		10.5rem;
$h2-font-size: 		7rem;
$h3-font-size: 		4.2rem;
$h4-font-size: 		3.2rem;
$h5-font-size: 		2.4rem;
$h6-font-size: 		1.8rem;
$body-font-size:	1.8rem;
$min-font-size: 	1.6rem;

$display-font:	 	'Comicy', sans-serif;
$body-font: 		'Lexend Deca', sans-serif;

// Logo
$logo-size: 		5rem;

// Animation
$transition-speed: 	.25s;

// Borders

$border: 0.4rem;
$border-radius: 1rem;
