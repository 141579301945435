
@mixin outline-shadowed-text($o, $s1, $s2, $col) {
	text-shadow: $s1 $s2 0 $col, ($s1 - 0.1) $s2 0 $col;
	-webkit-text-stroke: $o $col; }

// ---------

html {
	font-size: 10px; }

// ---------

body, h4, h5, h6, p, a {
	font-family: $body-font;
	font-size: $body-font-size;
	font-weight: 300 {
    word-break: normal; } }

// ---------

h1, h2, h3 {
	font-family: $display-font {
    word-break: normal; }
	font-weight: 300;
	line-height: 1.1; }

// ---------

h1 {
	color: white;
	@include responsive-font-size($h1-font-size);
	@include outline-shadowed-text(0.6rem, -1rem, 1rem, $theme-color-04);

	@include respond-mobile {
		@include outline-shadowed-text(0.3rem, -0.5rem, 0.5rem, $theme-color-04); } }


// ---------

h2 {
	@include responsive-font-size($h2-font-size);
	text-transform: uppercase;
	margin-bottom: $margin-unit * 1.5;
	color: $theme-color-05;
	@include outline-shadowed-text(0.4rem, -0.8rem, 0.8rem, $theme-color-04);

	@include respond-tablet {
		margin-bottom: $margin-unit; }

	@include respond-mobile {
		@include outline-shadowed-text(0.3rem, -0.5rem, 0.5rem, $theme-color-04); } }

// ---------

h3 {
	@include responsive-font-size($h3-font-size);
	text-transform: uppercase;
	color: $theme-color-05;
	@include outline-shadowed-text(0.3rem, -0.6rem, 0.6rem, $theme-color-04); }

// ---------

h4 {
	@include responsive-font-size($h4-font-size);
	text-transform: uppercase;
	margin-bottom: $margin-unit * 0.75;
	line-height: 2;

	@include respond-mobile {
		line-height: 1.8; } }

// ---------

h5 {
	@include responsive-font-size($h5-font-size);
	text-transform: uppercase;
	margin: 0;
	line-height: 2;

	@include respond-mobile {
		line-height: 1.8; } }

// ---------

h6 {
	@include responsive-font-size($h6-font-size);
	text-transform: uppercase;
	margin: 0;
	line-height: 2;

	@include respond-mobile {
		line-height: 1.8; } }

// ---------


p {
	line-height: 2;
	margin-top: $margin-unit * 0.75;
	margin-bottom: $margin-unit * 0.75;

	a {
		color: $theme-color-02;
		font-weight: 400;

		&:hover {
			color: darken($theme-color-02, 10%); }

		&:active {
			color: $theme-color-05; } }

	@include respond-mobile {
		line-height: 1.8; } }

// ---------

span {
	font-family: $body-font; }

// ---------

a, button {
	@include prefix(transition, $transition-speed);
	text-decoration: none; }

// ---------

.bold, b {
	font-weight: 700; }

// ---------

.uppercase {
	text-transform: uppercase; }

// ---------

.lowercase {
	text-transform: lowercase; }

// ---------

.boxed-text {
	display: inline-block;
	padding: $padding-unit * 0.5;
	padding-bottom: $padding-unit * 0.5 - .3rem; }

// --------

.notice {
	background-color: $theme-color-02;
	padding: $padding-unit * 0.5 $padding-unit * 0.75;
	border-radius: $border-radius;
	display: inline-block;

	i {
		margin-right: $margin-unit * 0.25; } }
