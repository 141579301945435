.breadcrumbs {
    margin-bottom: $margin-unit * 0.5;
    position: relative;
    z-index: 1;

    a {
        position: relative;
        display: inline-block;
        margin: $margin-unit * 0.15 0;
        margin-right: $margin-unit * 0.25;

        &:first-child {
            margin-left: 0;
            .text {
                padding-left: $padding-unit * 0.5; } }

        &:last-child {
            .text {
                padding-right: $padding-unit * 0.5;
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius; }
            .endcap {
                display: none; } }

        &:hover {
            .endcap {
                color: $theme-color-02; }
            .text {
                background-color: $theme-color-02; } }

        &:active {
            .endcap {
                color: $theme-color-05; }
            .text {
                color: $theme-color-04;
                background-color: $theme-color-05; } }

        .cutout, .endcap {
            position: absolute;
            font-size: 5.6rem;
            top: -1.05rem;
            @include prefix(transition, $transition-speed); }

        .cutout {
            left: -1rem;
            color: $theme-color-01;
            z-index: 1; }

        .endcap {
            right: -1.8rem;
            color: $theme-color-03;
            z-index: 2; }

        .text {
            display: block;
            color: $theme-color-01;
            background-color: $theme-color-03;
            text-transform: uppercase;
            font-size: $min-font-size;
            padding: $padding-unit * 0.25;
            padding-left: $padding-unit;
            border-radius: $border-radius;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            user-select: none;
            @include prefix(transition, $transition-speed); } } }


