.filter-checkbox {}

// --------------------------------------------

.filter {
    *:focus-visible {
        outline-color: $theme-color-01!important; }

    .bubble, .sidebar-content {
        background-image: url('../../../_img/pink-background.png'); }

    .bubble {
        top: 13rem;
        border-color: $theme-color-04;

        i {
            color: $theme-color-04; }

        &::after {
            background-image: url('../../../_img/pink-arrow-right.png'); }

        @include respond-mobile {
            top: 9rem; } }

    .sidebar-content {
        background-position: 42rem 15.5rem;
        .heading {
            margin-top: 14rem;
            margin-bottom: $margin-unit;
            margin-left: $margin-unit;

            @include respond-mobile {
                margin-top: 12rem; }

            h3 {
                margin-bottom: 0;
                font-size: $h3-font-size!important; }

            .clear-btn {
                margin-top: 1rem;
                margin-left: -0.5rem; } }

        .clear-btn {
            background: transparent;
            border: 0;
            outline: 0;
            padding: 0;
            font-family: $body-font;
            font-size: $body-font-size;
            font-weight: 300;
            cursor: pointer;
            color: $theme-color-07;

            i {
                margin-right: 0.5rem; }

            &:active {
                @include grow(0.9); } }

        .filter-section {
            margin: $margin-unit;
            margin-bottom: $margin-unit * 2; }

        .filter-section-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: $margin-unit * 0.6;

            h5 {
                color: $theme-color-01;
                position: relative;
                padding: 0 0.5rem;
                font-weight: 400;

                &::before {
                    content: '';
                    background: $theme-color-04;
                    @include highlight;
                    z-index: -1; } } }

        .filter-checkbox {
            label {
                display: block;
                cursor: pointer;
                margin: 0.5rem 0;

                span {
                    margin-left: 0.6rem;
                    user-select: none; }

                i {
                    -webkit-text-stroke: 0.2rem $theme-color-04; }

                .fa-solid.fa-square {
                    color: transparent; }

                .fa-solid.fa-square-check {
                    color: $theme-color-01; } } } } }
